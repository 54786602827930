// GET_REELS
export const GET_REELS = "GET_REELS";

// GET_REEL_INFO
export const GET_REEL_INFO = "GET_REEL_INFO";
export const GET_REEL_LIKE = "GET_REEL_LIKE";
export const GET_REEL_COMMENT = "GET_REEL_COMMENT";

export const GET_REPORTED_REELS = "GET_REPORTED_REELS";

// DELETE_REEEL
export const DELETE_REELS = "DELETE_REELS";
