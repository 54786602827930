
import * as ActionType from "./setting.type";
const initialState = {
  setting: [],
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SETTING:
      return {
        ...state,
        setting: action.payload,
      };
    case ActionType.UPDATE_SETTING:
      return {
        ...state,  
      };

      case ActionType.GET_DAILY_REWARD:
        console.log('action?.paylaod', action?.paylaod?.data)
        return {
          ...state,
          dailyReward: action.payload,
        };

        case ActionType.ADD_DAILY_REWARD:
          let dailyRewardAdd = [...state.dailyReward];
          dailyRewardAdd.unshift(action.payload);
          return {
            ...state,
            dailyReward: dailyRewardAdd,
          };

        case ActionType.EDIT_DAILY_REWARD:
          const updatedDailyReward = state.dailyReward?.map((item) => {
            if (item?._id === action.payload.id) {
              return {
                ...item,
                ...action.payload.data,
              };
            }
            return item;
          });
          return {
            ...state,
            dailyReward: updatedDailyReward,
          };

          case ActionType.DELETE_DAILY_REWARD:
            return {
              ...state,
              dailyReward: state.dailyReward.filter(
                (data) => !action?.payload.includes(data?._id)
              ),
            };
    //Handle Update Switch Value
    case ActionType.HANDLE_TOGGLE_SWITCH:
      
      return {
        ...state,
        setting: action.payload.setting,
      };
    default:
      return state;
  }
};
