import axios from "axios";
import * as ActionType from "./service.type";
import { apiInstanceFetch } from "../../../util/api";
import { setToast } from "../../../util/toast";

export const getservice = () => (dispatch) => {
  apiInstanceFetch
    .get(`serviceCategory/getServiceCategory`)
    .then((res) => {
      //
      dispatch({
        type: ActionType.GET_SERVICE,
        payload: res.service,
      });
    })
    .catch((error) => console.error(error));
};

// CREATE_service
export const createservice = (formData) => (dispatch) => {
  axios
    .post(`serviceCategory/create`, formData)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "service Create successfully");
        dispatch({
          type: ActionType.CREATE_SERVICE,
          payload: res.data.service,
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// UPDATE_service

export const updateservice = (formData, id) => (dispatch) => {
  axios
    .patch(`serviceCategory/update?serviceCategoryId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_SERVICE,
          payload: { data: res.data.service, id },
        });
        setToast("success", "service update successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};

// DELETE_service

export const deleteservice = (id) => (dispatch) => {
  axios
    .delete(`serviceCategory/delete?serviceId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_SERVICE,
          payload: id,
        });
        setToast("success", "service Delete successfully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error.message));
};


