// GET_SUB_Category
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";

// GET_CATEGORY_WISE_SUBCATEGORY
export const GET_CATEGORY_WISE_SUBCATEGORY = "GET_CATEGORY_WISE_SUBCATEGORY";

// CREATE_SUB_CATEGORY
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
// EDIT_SUB_CATEGORY

export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";

// DELETE_SUB_CATEGORY

export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";

// Service_sub_category :-
export const GET_CATEGORY_WISE_SERVICE_SUBCATEGORY =
  "GET_CATEGORY_WISE_SERVICE_SUBCATEGORY";

// CREATE_SERVICE_SUB_CATEGORY
export const CREATE_SUB_SERVICE_CATEGORY = "CREATE_SUB_SERVICE_CATEGORY";

// Update_service_sub_category
export const UPDATE_SUB_SERVICE_CATEGORY = "UPDATE_SUB_SERVICE_CATEGORY";

// DELETE_SUB_CATEGORY

export const DELETE_SUB_SERVICE_CATEGORY = "DELETE_SUB_SERVICE_CATEGORY";
