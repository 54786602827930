import React, { useEffect, useState } from "react";
import SettingBox from "../SettingBox";
import Input from "../../../extra/Input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Button";
import { getSetting, updateSetting } from "../../../store/setting/setting.action";

export const RefralReward = () => {
  const { setting } = useSelector((state) => state.setting);

  console.log('setting', setting)
  const [refralRewardCoin, setrefralRewardCoin] = useState("");
  const dispatch = useDispatch();

  const [error, setError] = useState({
    refralRewardCoin: "",
    zegoAppSignIn: "",
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    // box 1
    setrefralRewardCoin(setting?.referralRewardCoins);
  }, [setting]);

  const handleSubmit = () => {
    if (!refralRewardCoin) {
      let error = {};
      if (!refralRewardCoin) error.refralRewardCoin = "refralRewardCoin Is Required ";

      return setError({ ...error });
    } else {
      let settingData = {
        referralRewardCoins : parseInt(refralRewardCoin),
      };

      dispatch(updateSetting(settingData, setting?._id));
    }
  };

  return (
    <div className="settingMain">
      <div className="col-12 d-flex justify-content-end">
        <Button
          newClass={`whiteFont`}
          btnName={`Submit`}
          btnColor={`btnBlackPrime`}
          style={{ width: "90px", borderRadius: "6px" }}
          onClick={(e) => handleSubmit(e)}
        />
      </div>
      <SettingBox title={`Referral Setting`}>
        <Input
          type={`text`}
          label={`Referral Reward Coin`}
          value={refralRewardCoin}
          newClass={`col-12`}
          placeholder={`Enter Reward Coin`}
          errorMessage={error.refralRewardCoin && error.refralRewardCoin}
          onChange={(e) => {
            setrefralRewardCoin(e.target.value);
            if (!e.target.value) {
              return setError({
                ...error,
                refralRewardCoin: `RefralRewardCoin Is Required`,
              });
            } else {
              return setError({
                ...error,
                refralRewardCoin: "",
              });
            }
          }}
        />
      </SettingBox>
    </div>
  );
};
