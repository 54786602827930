import * as ActionType from "./withdrawRequest.type"

const initialState = {
  withdrawRequest: [],
  total: 0,
};

export const withdrawRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_WITHDRAW_REQUEST:
     
      return {
        ...state,
        withdrawRequest: action.payload.request,
        total: action.payload.total,
      };
    case ActionType.ACCEPT_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawRequest: state.withdrawRequest.filter(
          (data) => !action.payload.requestId.includes(data?._id)
        ),
      };
    case ActionType.DECLINE_WITHDRAW_REQUEST:


      return {
        ...state,
        withdrawRequest: state.withdrawRequest.filter(
          (data) => !action.payload.requestId.includes(data?._id)
        ),
      };
    default:
      return state;
  }
};
