// GET_Category
export const GET_CATEGORY = "GET_CATEGORY";


// CREATE_CATEGORY
export const CREATE_CATEGORY = "CREATE_CATEGORY";
// EDIT_CATEGORY

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

// DELETE_CATEGORY

export const DELETE_CATEGORY = "DELETE_CATEGORY"



// GET_SUB_Category
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";

// GET_CATEGORY_WISE_SUBCATEGORY
export const GET_CATEGORY_WISE_SUBCATEGORY = "GET_CATEGORY_WISE_SUBCATEGORY";

// CREATE_SUB_CATEGORY
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
// EDIT_SUB_CATEGORY

export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";

// DELETE_SUB_CATEGORY

export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY"




