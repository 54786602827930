import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { declineRequest } from "../../store/withdrawrequest/withdrawRequest.action";
// import { declineRequest } from "../../store/withdraw/withdraw.action";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const Info = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [contactUsDialogue, setContectUsDialogue] = useState(false);

  const [error, setError] = useState({
    reason: "",
  });
  useEffect(() => {
    setContectUsDialogue(dialogue);
  }, [dialogue]);

  const handleSubmit = () => {
    if (!reason) {
      let error = {};
      if (!reason) error.reason = "Reason is Required.";

      return setError({ ...error });
    } else {
      dispatch(declineRequest(dialogueData?._id, reason));
      handleCloseAddCategory();
    }
  };
  const handleCloseAddCategory = () => {
    setContectUsDialogue(false);
    dispatch({
      type: CLOSE_DIALOGUE,
      payload: {
        dialogue: false,
      },
    });

    let dialogueData_ = {
      dialogue: false,
    };
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  return (
    <div>
      <Modal
        open={contactUsDialogue}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Payment Detail Info
          </Typography>
          <form>
            <div className="mt-2">
              <Input
                label={"Payment Gateway"}
                name={"Payment Gateway"}
                placeholder={"Enter Reason..."}
                value={dialogueData?.paymentGateway}
                readOnly
              />
              <div className="prime-input w-100">
                <label style={{fontSize : "14px" , fontWeight : 500 , color : "#000"}}>
                  Payment Details
                </label>

                  {dialogueData?.paymentDetails?.length > 0 ? (
                    dialogueData.paymentDetails.map((item, index) => (
                      <div key={index} className="mt-1 col-12">
                        <p className="mb-1">
                          <span
                            style={{
                              minWidth: "100px",
                              display: "inline-block",
                            }}
                          >
                            {item?.split(":")[0]}
                          </span>
                          <div className="mt-2" style={{width:"559px"}}>
                            <Input value={item?.split(":")[1]} readOnly />
                          </div>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No payment details available.</p>
                  )}
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnColor={`bg-danger text-white`}
                btnName={"Close"}
                newClass={"close-model-btn"}
                style={{
                  borderRadius: "0.5rem",
                  width: "80px",
                  marginLeft: "10px",
                }}
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Info;
