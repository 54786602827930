// GET PROMOCODE
export const GET_PROMOCODE = "GET_PROMOCODE";

// CREATE PROMOCODE
export const CREATE_PROMOCODE = "CREATE_PROMOCODE";


// UPDATE PROMOCODE
export const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";

// DELETE PROMOCODE
export const DELETE_PROMOCODE = "DELETE_PROMOCODE";


