import React, { useState } from "react";
import Button from "../../extra/Button";
import  PendingRequest  from "./PendingRequest";
import  AcceptedRequest  from "./AcceptedRequest";
import  DeclinedRequest  from "./DeclinedRequest";

export const UserWithdrawRequest = () => {
  const [status, setStatus] = useState("Pending");
  return (
    <>
    <div className="row mt-2">
      <div className="col-6 d-flex" id="">
        <Button
          newClass={`themeFont mx-2 text-light ${
            status === "Pending" ? "create-btn" : "default-btn"
          }`}
          btnName={`Pending`}
          style={{
            borderRadius: "5px",
            width: "auto",

            cursor: "pointer",
          }}
          onClick={() => setStatus("Pending")}
        />
        <Button
          newClass={`themeFont mx-2 text-light ${
            status === "Accepted" ? "update-btn" : "default-btn"
          }`}
          btnName={`Accepted`}
          style={{
            borderRadius: "5px",
            width: "auto",

            cursor: "pointer",
          }}
          onClick={() => setStatus("Accepted")}
        />

        <Button
          newClass={`themeFont mx-2 text-light ${
            status === "Declined" ? "engagement-btn" : "default-btn"
          }`}
          btnName={`Declined`}
          style={{
            borderRadius: "5px",
            width: "auto",
            backgrounColor : "red",
            cursor: "pointer",
          }}
          onClick={() => setStatus("Declined")}
        />
      </div>
    </div>
    <div>
      {status === "Pending" && <PendingRequest /> }
      {status === "Accepted" && <AcceptedRequest /> }
      {status === "Declined" && <DeclinedRequest /> }


    </div>
  </>
  )
};
