import React, { useEffect, useState } from "react";
import NewTitle from "../../extra/Title";
import { useDispatch, useSelector } from "react-redux";
import { getWithDrawRequest } from "../../store/withdrawrequest/withdrawRequest.action";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Reason from "./Reason";
import AcceptRequestDialog from "./AcceptRequestDialog";
import Info from "./Info";

const PendingRequest = (props) => {
  const { withdrawRequest, total } = useSelector((state) => state.withdrawRequest);
  const { dialogueType } = useSelector((state) => state.dialogue);

  console.log('withdrawRequest', withdrawRequest)

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showURLs, setShowURLs] = useState([]);

  const [size, setSize] = useState(20);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getWithDrawRequest(1, page, size));
  }, [dispatch, page, size]);

  useEffect(() => {
    setData(withdrawRequest);
  }, [withdrawRequest]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const ManageUserData = [
    {
      Header: "No",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "User",
      body: "userName",
      Cell: ({ row, index }) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ cursor: "pointer"}}
        >
          <img src={row?.userId?.image} width="40px" height="40px" style={{marginRight : "10px"}} />
          <span className="text-capitalize cursorPointer text-nowrap">
            {row?.userId?.firstName}
          </span>
        </div>
      ),
    },

    {
      Header: "Amount",
      body: "amount",
      Cell: ({ row }) => <span>{row?.amount}</span>,
    },

    {
      Header: "Coin",
      body: "coin",
      Cell: ({ row }) => <span>{row?.coin}</span>,
    },
    {
      Header: "Date",
      body: "date",
      Cell: ({ row }) => (
        <span>{row.requestDate.split(', ')[0]}</span>
      ),
    },
    
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`themeFont boxCenter userBtn fs-4`}
          btnColor={``}
          btnIcon={`bi bi-info-circle`}
          onClick={() => handleInfo(row,"info")}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
          }}
        />
      )
    },

    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button d-flex justify-content-center gap-2 align-items-center">
          <Button
            btnName={`Accept`}
            newClass={`fw-bolder bg-success text-white w-70`}
            onClick={() => handleEdit(row, "accept")}
          />
          <div className="ml-3">
            <Button
              btnName={`Decline`}
              newClass={`fw-bolder bg-danger text-white w-70`}
              onClick={() => handleDecline(row, "decline")}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleInfo = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });
  };

  const handleEdit = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type: type,
      dialogueData: row,
    };
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleDecline = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });
    console.log("dialogueTypedialogueTypedialogueType", dialogueType);
    let dialogueData_ = {
      dialogue: true,
      type: type,
      dialogueData: row,
    };
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  return (
    <div>
      <div className="user-table real-user mb-3">
        {dialogueType == "decline" && <Reason />}
        {dialogueType == "accept" && <AcceptRequestDialog />}
        {dialogueType == "info" && <Info />}



        <div className="mt-5">
          <Table
            data={data}
            mapData={ManageUserData}
            serverPerPage={size}
            serverPage={page}
            type={"server"}
          />
        </div>

        <Pagination
          type={"server"}
          activePage={page}
          rowsPerPage={size}
          userTotal={total}
          setPage={setPage}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PendingRequest;
