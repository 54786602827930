// get_ attribute

export const GET_ATTRIBUTE = "GET_ATTRIBUTE";

// GET_TYPE_ATTRIBUTE
export const GET_TYPE_ATTRIBUTE = "GET_TYPE_ATTRIBUTE"

//  create attribute
export const CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE";

// update attribute
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";

// delete attribute
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
