import React, { useEffect, useState } from "react";
import NewTitle from "../../extra/Title";
import { useDispatch, useSelector } from "react-redux";
import { getWithDrawRequest } from "../../store/withdrawrequest/withdrawRequest.action";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import Reason from "./Reason";

const AcceptedRequest = (props) => {
  const { withdrawRequest, total } = useSelector(
    (state) => state.withdrawRequest
  );
  const { dialogueType } = useSelector((state) => state.dialogue);

  console.log("withdrawRequest", withdrawRequest);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showURLs, setShowURLs] = useState([]);

  const [size, setSize] = useState(20);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getWithDrawRequest(2, page, size));
  }, [dispatch, page, size]);

  useEffect(() => {
    setData(withdrawRequest);
  }, [withdrawRequest]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const ManageUserData = [
    {
      Header: "No",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },

    {
      Header: "User",
      body: "userName",
      Cell: ({ row, index }) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ cursor: "pointer" }}
        >
          <img
            src={row?.userId?.image}
            width="40px"
            height="40px"
            style={{ marginRight: "10px" }}
          />
          <span className="text-capitalize cursorPointer text-nowrap">
            {row?.userId?.firstName}
          </span>
        </div>
      ),
    },

    {
      Header: "Amount",
      body: "amount",
      Cell: ({ row }) => <span>{row?.amount}</span>,
    },

    {
      Header: "Coin",
      body: "coin",
      Cell: ({ row }) => <span>{row?.coin}</span>,
    },

    {
      Header: "Payment Gateway",
      body: "paymentGateway",
      Cell: ({ row }) => <span>{row?.paymentGateway}</span>,
    },

    {
      Header: "Date",
      body: "date",
      Cell: ({ row }) => (
        <span>{row.requestDate.split(', ')[0]}</span>
      ),
    },
  ];

  return (
    <div>
      <div className="user-table real-user mb-3">
        {dialogueType == "decline" && <Reason />}

        <div className="mt-5">
          <Table
            data={data}
            mapData={ManageUserData}
            serverPerPage={size}
            serverPage={page}
            type={"server"}
          />
        </div>

        <Pagination
          type={"server"}
          activePage={page}
          rowsPerPage={size}
          userTotal={total}
          setPage={setPage}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AcceptedRequest;
