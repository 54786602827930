// GET FAQ
export const GET_FAQ = "GET_FAQ";

// CREATE FAQ
export const CREATE_FAQ = "CREATE_FAQ";


// UPDATE FAQ
export const UPDATE_FAQ = "UPDATE_FAQ";

// DELETE FAQ
export const DELETE_FAQ = "DELETE_FAQ";