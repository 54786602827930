import * as ActionType from "./service.type";

const initialState = {
  service: [],
};

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SERVICE:
      return {
        ...state,
        service: action.payload,
      };

    case ActionType.UPDATE_SERVICE:
      return {
        ...state,
        service: state.service.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.DELETE_SERVICE:
      return {
        ...state,
        service: state.service.filter(
          (data) => data._id !== action.payload && data
        ),
      };

    case ActionType.CREATE_SERVICE:
      return {
        ...state,
        service: [action.payload, ...state.service], // Create a new array with the new service first
      };

    default:
      return state;
  }
};
