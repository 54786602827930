import React, { useEffect, useState } from "react";
import SettingBox from "../SettingBox";
import Input from "../../../extra/Input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Button";
import {
  getSetting,
  updateSetting,
} from "../../../store/setting/setting.action";

export const EngagementReward = () => {
  const { setting } = useSelector((state) => state.setting);

  console.log("setting", setting);
  const [watchingVideoRewardCoins, setwatchingVideoRewardCoins] = useState("");
  const [watchingProductRewardCoins, setWatchingProductRewardCoins] =
    useState("");

  const dispatch = useDispatch();

  const [error, setError] = useState({
    watchingVideoRewardCoins: "",
    watchingProductRewardCoins: "",
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  useEffect(() => {
    // box 1
    setwatchingVideoRewardCoins(setting?.watchingVideoRewardCoins);
    setWatchingProductRewardCoins(setting?.watchingProductRewardCoins);
  }, [setting]);

  const handleSubmit = () => {
    if (!watchingVideoRewardCoins) {
      let error = {};
      if (!watchingVideoRewardCoins)
        error.watchingVideoRewardCoins =
          "watchingVideoRewardCoins Is Required ";

      return setError({ ...error });
    } else {
      let settingData = {
        watchingVideoRewardCoins: parseInt(watchingVideoRewardCoins),
        watchingProductRewardCoins: parseInt(watchingProductRewardCoins),
      };

      dispatch(updateSetting(settingData, setting?._id));
    }
  };

  return (
    <div className="settingMain">
      <div className="col-12 d-flex justify-content-end">
        <Button
          newClass={`whiteFont`}
          btnName={`Submit`}
          btnColor={`btnBlackPrime`}
          style={{ width: "90px", borderRadius: "6px" }}
          onClick={(e) => handleSubmit(e)}
        />
      </div>
      <SettingBox title={`Engagement Setting`}>
        <Input
          type={`text`}
          label={`Video View Reward Coin`}
          value={watchingVideoRewardCoins}
          newClass={`col-12`}
          placeholder={`Enter Video Reward Coin`}
          errorMessage={
            error.watchingVideoRewardCoins && error.watchingVideoRewardCoins
          }
          onChange={(e) => {
            setwatchingVideoRewardCoins(e.target.value);
            if (!e.target.value) {
              return setError({
                ...error,
                watchingVideoRewardCoins: `Video Reward Coins is Required!`,
              });
            } else {
              return setError({
                ...error,
                watchingVideoRewardCoins: "",
              });
            }
          }}
        />

        <Input
          type={`text`}
          label={`Product View Reward Coin`}
          value={watchingProductRewardCoins}
          newClass={`col-12`}
          placeholder={`Enter Product Reward Coin`}
          errorMessage={
            error.watchingProductRewardCoins && error.watchingProductRewardCoins
          }
          onChange={(e) => {
            setWatchingProductRewardCoins(e.target.value);
            if (!e.target.value) {
              return setError({
                ...error,
                watchingProductRewardCoins: `Product Coin Is Required`,
              });
            } else {
              return setError({
                ...error,
                watchingProductRewardCoins: "",
              });
            }
          }}
        />
      </SettingBox>
    </div>
  );
};
