import React, { useState } from "react";
import Button from "../../../extra/Button";
import Searching from "../../../extra/Searching";
import DailyCheckInReward from "./DailyCheckInreward";
import { RefralReward } from "./RefralReward";
import { EngagementReward } from "./EngagementReward";

export const Reward = () => {
  const [status, setStatus] = useState("Daily CheckIn Reward");
  return (
    <>
      <div className="row mt-2">
        <div className="col-6 d-flex" id="">
          <Button
            newClass={`themeFont mx-2 text-light ${
              status === "Daily CheckIn Reward" ? "create-btn" : "default-btn"
            }`}
            btnName={`Daily CheckIn Reward`}
            style={{
              borderRadius: "5px",
              width: "auto",

              cursor: "pointer",
            }}
            onClick={() => setStatus("Daily CheckIn Reward")}
          />
          <Button
            newClass={`themeFont mx-2 text-light ${
              status === "Refral Reward" ? "update-btn" : "default-btn"
            }`}
            btnName={`Referral Reward`}
            style={{
              borderRadius: "5px",
              width: "auto",

              cursor: "pointer",
            }}
            onClick={() => setStatus("Refral Reward")}
          />

          <Button
            newClass={`themeFont mx-2 text-light ${
              status === "Engagement Reward" ? "engagement-btn" : "default-btn"
            }`}
            btnName={`Engagement Reward`}
            style={{
              borderRadius: "5px",
              width: "auto",
              backgrounColor : "red",
              cursor: "pointer",
            }}
            onClick={() => setStatus("Engagement Reward")}
          />
        </div>
      </div>
      <div>
        {status === "Daily CheckIn Reward" && <DailyCheckInReward /> }
        {status === "Refral Reward" && <RefralReward /> }
        {status === "Engagement Reward" && <EngagementReward /> }


      </div>
    </>
  );
};
