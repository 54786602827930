// GET_SERVICE
export const GET_SERVICE = "GET_SERVICE";


// CREATE_SERVICE
export const CREATE_SERVICE = "CREATE_SERVICE";
// EDIT_SERVICE

export const UPDATE_SERVICE = "UPDATE_SERVICE";

// DELETE_SERVICE

export const DELETE_SERVICE = "DELETE_SERVICE"









